import Icon from '@ant-design/icons';
import React from 'react';

const LogoSvg = () => (
	<svg width="1em" height="1em" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M188 552 l-128 -77 0 -155 0 -155 130 -78 130 -78 130 76 130 77 0
158 0 158 -129 76 c-71 42 -131 76 -132 76 -2 -1 -61 -35 -131 -78z m122 -6
l0 -54 -62 -37 -62 -37 -43 21 c-24 11 -43 24 -43 28 0 6 195 131 208 133 1 0
2 -24 2 -54z m133 -11 c53 -32 97 -61 97 -65 0 -15 -73 -51 -89 -45 -9 3 -40
20 -68 38 l-53 31 0 54 c0 29 3 51 8 49 4 -2 51 -30 105 -62z m-55 -99 l54
-33 -61 -36 -61 -36 -60 36 -60 35 52 34 c29 18 60 34 68 34 8 0 39 -15 68
-34z m-223 -116 l0 -82 -42 -25 -43 -25 0 132 0 132 43 -25 42 -25 0 -82z
m395 -1 l0 -131 -42 25 -43 25 -3 80 -3 80 43 25 c24 15 44 26 46 27 1 0 2
-59 2 -131z m-304 29 c33 -20 54 -40 54 -50 0 -10 5 -18 10 -18 6 0 10 8 10
18 0 15 93 82 114 82 3 0 6 -31 6 -69 l0 -69 -57 -36 c-44 -26 -59 -31 -61
-20 -4 18 -20 18 -24 0 -2 -11 -17 -6 -60 20 l-58 36 0 69 c0 38 3 69 6 69 3
0 30 -15 60 -32z m-7 -164 l61 -36 0 -55 c0 -55 0 -55 -22 -41 -13 8 -60 37
-105 64 -46 27 -83 52 -83 55 0 9 61 47 77 48 6 1 39 -15 72 -35z m255 15 c20
-12 36 -24 36 -29 0 -6 -127 -85 -202 -127 -5 -2 -8 20 -8 49 l0 53 58 37 c31
20 62 37 69 37 6 1 28 -8 47 -20z"/>
<path d="M500 370 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10z"/>
<path d="M500 320 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10z"/>
<path d="M500 270 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10z"/>
<path d="M310 240 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0
-10 -9 -10 -20z"/>
</g>
  </svg>
	
);

type Props = {
  size: string | number;
};

export const Logo: React.FC<Props> = (props) => {
  return <Icon component={LogoSvg} style={{ fontSize: props.size }} />;
};
