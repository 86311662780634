import { CustomerServiceOutlined, SearchOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space } from 'antd';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, withLayout } from '../hocs/withLayout';
import { useEffectOnce } from '../hooks/useEffectOnce';
import { useRoutingBehavior } from '../hooks/useRoutingBehavior';
import { compose } from '../util/compose';
import { Box } from './Box';
import { ImFeelingLucky } from './ImFeelingLucky';
import { Logo } from './Logo';
import { Wordmark } from './Wordmark';

const LANDING_SRC = 'static/landing.jpg';

const Outer = styled.div`
  background-color: white;
`;

const Jumbotron = styled.div`
  padding-top: 128px;
  padding-bottom: 128px;
  text-align: center;
  background-image: url("${LANDING_SRC}");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const WhiteBox = styled(Box)`
  width: 55%;
  min-width: 300px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  color: ${(props) => props.theme['@primary-color']};
`;

const Header = styled.h1`
  font-size: 3em;
  font-weight: lighter;
  margin-bottom: 0;
  background: white;
`;

const SubHeader = styled.h2`
  font-size: 1.2em;
  margin-bottom: 1em;
`;

const Title = styled.h3`
  color: ${(props) => props.theme['@primary-color']};
`;

const Footer = styled.div`
  padding: 24px 48px;
  text-align: center;
  display: flex;

  svg {
    width: 2.5em;
    height: 2.5em;
    color: ${(props) => props.theme['@secondary-color']};
  }
`;

type Props = {};

const enhance = compose(withLayout(Layout.DEFAULT));

export const Landing: React.FC<Props> = enhance((props: Props) => {
  const { shouldRedirectFromLandingToLibrary, recordLandingVisit } = useRoutingBehavior();

  useEffectOnce(() => {
    recordLandingVisit();
  });

  return (
    <Outer data-testid="landing">
      {shouldRedirectFromLandingToLibrary && <Navigate to="/library" replace />}

      <Jumbotron>
        <Overlay />
        <WhiteBox>
          <Logo size="5em" />
          <Header>
            <Wordmark />
          </Header>
          <SubHeader>Погрузись в мир музыки, играй и развивайся</SubHeader>

          <Space>
            <Link to="/library" className="ant-btn ant-btn-primary">
              погрузиться в мир музыки
            </Link>
            <ImFeelingLucky />
          </Space>
        </WhiteBox>
      </Jumbotron>

      <Footer>
        <Row align="middle" justify="space-around">
          <Col xs={24} md={6}>
            <ThunderboltOutlined />
            <Title>Развивайся эффективней</Title>
            <p>Учись играть продуктивней, зацикливая и замедляя участки композиции</p>
          </Col>

          <Col xs={0} md={1}>
            <Divider type="vertical" style={{ height: 72 }} />
          </Col>

          <Col xs={24} md={6}>
            <CustomerServiceOutlined />
            <Title>Совершенствуй навыки</Title>
            <p>Улучшай импровизацию и пробуй играть более сложные музыкальные фразы с помощью инструментов визуализации грифа</p>
          </Col>

          <Col xs={0} md={1}>
            <Divider type="vertical" style={{ height: 72 }} />
          </Col>

          <Col xs={24} md={6}>
            <SearchOutlined />
            <Title>Открывай новое</Title>
            <p>Тэг-система поможет найти исполнителей любого жанра - от джаза до метала. Открой вдохновляющих музыкантов и развивай свои музыкальные навыки вместе с ними</p>
          </Col>
        </Row>
      </Footer>
    </Outer>
  );
});
