const theme = {
  '@primary-color': '#0294A5',
  '@secondary-color': '#03353E',
  '@tertiary-color': '#121F40',
  '@highlight-color': '#C1403D',
  '@background-color-base': '#A79C93',
  '@muted': '#999999',
  '@border-color': '#04060F',
};

module.exports = { theme };
